.App {
  padding: 20px;
}

.App h1 {
  margin-top: 0;
}

.participant {
  display: flex;
  width: 100%;
  max-width: 600px;
}

.participantName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
}

.participantChoices {
  overflow: hidden;
  white-space: nowrap;
  width: 235px;
}
